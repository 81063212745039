import React from 'react';
import { HiPlus } from 'react-icons/hi';

import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '@amfintech/react-admin-ui';
import { Content } from '@amfintech/refine-react-admin';
import { useList, useNavigation } from '@refinedev/core';

import { AdminRole, ModificationEntityType } from '~/api';
import { AdminRoleCard } from '~/pages/admin-role/admin-role-card';
import { resourceNames } from '~/resources/resource-names';

export const AdminRoleListPage = ({
  title,
  renderModificationTable,
}: {
  title: string;
  renderModificationTable: (props: { filterConfig: any; options: any }) => React.ReactNode;
}) => {
  const navigateTo = useNavigation();
  const { data, isLoading } = useList<AdminRole>({
    resource: resourceNames.adminRole,
    pagination: {
      pageSize: 100,
    },
    metaData: {
      fields: ['id', 'name', 'description', 'permissions', 'userCount'],
    },
  });

  const content = React.useMemo(() => {
    return (
      <Tabs defaultValue="active">
        <TabsList>
          <TabsTrigger value="active">{'Active'}</TabsTrigger>
          <TabsTrigger value="pending">{'Pending Approval'}</TabsTrigger>
          <TabsTrigger value="history">{'History'}</TabsTrigger>
        </TabsList>
        <TabsContent value="active">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {!isLoading &&
              data?.data.map((role) => (
                <AdminRoleCard
                  key={role.id}
                  roleId={role.id}
                  role={role.name}
                  description={role.description ?? ''}
                  totalUsers={role.userCount ?? 0}
                />
              ))}
            <Button
              variant={'ghost'}
              className="h-full text-primary-500 rounded-lg border bg-card shadow-sm"
              onClick={() => navigateTo.create(resourceNames.adminRole)}
            >
              <HiPlus className="mr-2 h-4 w-4" /> Add New Role
            </Button>
          </div>
        </TabsContent>
        <TabsContent value="pending">
          {renderModificationTable({
            filterConfig: {
              initial: [
                {
                  field: 'entityType',
                  operator: 'eq',
                  value: ModificationEntityType.AdminRole,
                },
                {
                  field: 'status',
                  operator: 'eq',
                  value: 'Pending',
                },
              ],
            },
            options: {
              entityType: ModificationEntityType.AdminRole,
            },
          })}
        </TabsContent>
        <TabsContent value="history">
          {renderModificationTable({
            filterConfig: {
              initial: [
                {
                  field: 'entityType',
                  operator: 'eq',
                  value: ModificationEntityType.AdminRole,
                },
                {
                  field: 'status',
                  operator: 'in',
                  value: ['Approved', 'Rejected'],
                },
              ],
            },
            options: {
              entityType: ModificationEntityType.AdminRole,
            },
          })}
        </TabsContent>
      </Tabs>
    );
  }, [data]);

  return (
    <div className="w-full">
      <Content title={title}>{content}</Content>
    </div>
  );
};
