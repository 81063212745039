import { formatDate, StatusField, Switch } from '@amfintech/react-admin-ui';
import { createHelpers, createResource, sentenceCase } from '@amfintech/refine-react-admin';

import {
  AccountType,
  ModificationEntityType,
  Status,
  Transaction,
  TransactionStatus,
  TransactionType,
  User,
  type Account,
} from '~/api';

import { resourceNames } from './resource-names';
import { formatTransactionStatus } from './transaction-resource';

const {
  defineFields,
  defineCardSection,
  defineShowPage,
  defineTabsSection,
  defineRelatedResourceSection,
  defineRelatedCardSection,
} = createHelpers<Account>({
  resourceName: resourceNames.account,
});

export const formatUserStatus = (status?: Status) => {
  switch (status) {
    case Status.Active:
      return 'success';
    case Status.Inactive:
      return 'pending';
    case Status.Closed:
      return 'danger';
    case Status.ProfileLocked:
      return 'warn';
    default:
      return 'pending';
  }
};

export const customerResource = createResource({
  identifier: resourceNames.customer,
  name: resourceNames.account,
  label: 'Customer Profiles',
  fields: defineFields([
    'id',
    'type',
    'fullName',
    'salutation',
    'phoneNo',
    'nationality',
    'idType',
    'idNumber',
    'idExpiryDate',
    'dateOfBirth',
    'cityOfBirth',
    'countryOfBirth',
    'gender',
    'bumiputera',
    'race',
    'maritalStatus',
    'religion',
    'mothersMaidenName',
    'email',
    'permanentAddress',
    'mailingAddress',
    'city',
    'state',
    'postcode',
    'employmentStatus',
    'occupation',
    'jobIndustry',
    'profession',
    'companyName',
    'natureOfBusiness',
    'companyAddress',
    'officePhoneNo',
    'estimatedNetWorth',
    'annualIncome',
    'sourceOfFund',
    'purposeOfInvestment',
    'expectedInvestmentAmount',
    'frequencyOfTransaction',
    'politicalExposedPerson',
    'prominentRole',
    'taxResidency',
    'declaration',
    'safRating',
    'marketingPush',
    'amlSanctionScreeningPoint',
    'masterAccountNo',
    'applicationRefId',
    'applicationDate',
    'applicationStatus',
    'employmentType',
    'monthlyIncome',
    'sourceOfWealth',
    { users: ['id'] },
  ]),
  defaultValues: {},
  list: {
    filters: {
      required: true,
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityType: ModificationEntityType.User,
    },
  },
  filterControls: {
    idNumber: {
      type: 'text',
      config: {
        label: 'ID Number',
      },
      operator: 'contains',
    },
    type: {
      type: 'select',
      config: {
        label: 'Account Type',
        options: [
          { label: 'Individual', value: AccountType.Individual },
          { label: 'Corporate', value: AccountType.Corporate },
        ],
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'idNumber',
      header: 'ID Number',
      accessorKey: 'idNumber',
      cell: (data) => {
        const idNumber = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {idNumber}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'idType',
      header: 'ID Type',
      accessorKey: 'idType',
      cell: (data) => {
        return sentenceCase(data.cell.getValue<string>());
      },
    },
    {
      id: 'email',
      header: 'Email Address',
      accessorKey: 'email',
    },
    {
      id: 'users',
      header: 'User ID',
      accessorKey: 'users',
      cell: (data) => {
        return data.row.original.users.map((user) => user.id).join(', ');
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    sections: [
      defineTabsSection({
        tabs: [
          {
            label: 'Personal Information',
            content: [
              defineCardSection({
                title: 'Personal details',
                collapsible: 'expand-by-default',
                fields: [
                  'salutation',
                  'fullName',
                  'mothersMaidenName',
                  'gender',
                  'religion',
                  'race',
                  'maritalStatus',
                  'bumiputera',
                  'nationality',
                  'idType',
                  'idNumber',
                  'idExpiryDate',
                  'dateOfBirth',
                  'cityOfBirth',
                  'countryOfBirth',
                ],
                displays: {
                  bumiputera: {
                    label: 'Bumiputera',
                    render: (data: Account) => {
                      return <Switch checked={data.bumiputera} disabled />;
                    },
                  },
                  idType: {
                    label: 'ID Type',
                    render: (data: Account) => {
                      return data.idType;
                    },
                  },
                  idNumber: {
                    label: 'ID Number',
                    render: (data: Account) => {
                      return data.idNumber;
                    },
                  },
                  idExpiryDate: {
                    label: 'Passport Expiry Date',
                    render: (data: Account) => {
                      if (data.idType !== 'Passport') {
                        return '';
                      }
                      return formatDate(data.idExpiryDate, { formatType: 'dateOnly' });
                    },
                  },
                  dateOfBirth: {
                    label: 'Date of Birth',
                    type: 'date',
                    formatType: 'dateOnly',
                  },
                },
              }),
              defineCardSection({
                title: 'Contact details',
                collapsible: 'expand-by-default',
                fields: [
                  'phoneNo',
                  'email',
                  'permanentAddress',
                  'mailingAddress',
                  'city',
                  'state',
                  'postcode',
                  { users: ['status'] },
                ],
                displays: {
                  users: {
                    label: 'Status',
                    render: (data: Account) => (
                      <StatusField
                        status={formatUserStatus(data.users[0]?.status)}
                        label={data.users[0]?.status ?? ''}
                      />
                    ),
                  },
                },
              }),
              defineCardSection({
                title: 'Account details',
                collapsible: 'expand-by-default',
                fields: [
                  'type',
                  'employmentStatus',
                  'occupation',
                  'jobIndustry',
                  'profession',
                  'companyName',
                  'natureOfBusiness',
                  'companyAddress',
                  'officePhoneNo',
                  'estimatedNetWorth',
                  'annualIncome',
                  'sourceOfFund',
                  'purposeOfInvestment',
                  'expectedInvestmentAmount',
                  'frequencyOfTransaction',
                  'politicalExposedPerson',
                  'prominentRole',
                  'taxResidency',
                  'declaration',
                  'safRating',
                  'marketingPush',
                  'amlSanctionScreeningPoint',
                  'masterAccountNo',
                  'applicationRefId',
                  'applicationDate',
                  'applicationStatus',
                  'employmentType',
                  'monthlyIncome',
                  'sourceOfWealth',
                  'highNetWorth',
                ],
                displays: {
                  prominentRole: {
                    label: 'Prominent Role',
                    render: (data: Account) => {
                      return <Switch checked={data.prominentRole} disabled />;
                    },
                  },
                  politicalExposedPerson: {
                    label: 'Political Exposed Person',
                    render: (data: Account) => {
                      return <Switch checked={data.politicalExposedPerson} disabled />;
                    },
                  },
                  marketingPush: {
                    label: 'Marketing Push',
                    render: (data: Account) => {
                      return <Switch checked={data.marketingPush} disabled />;
                    },
                  },
                  highNetWorth: {
                    label: 'High Net Worth',
                    render: (data: Account) => {
                      return <Switch checked={data.highNetWorth} disabled />;
                    },
                  },
                },
              }),
            ],
          },
          {
            label: 'Maintenance',
            content: [
              defineRelatedCardSection<User>({
                title: 'Maintenance',
                collapsible: 'expand-by-default',
                relatedResourceName: resourceNames.user,
                relatedResourceFieldName: 'users',
                relatedResourceFields: ['status', 'updatedAt'],
                fields: [{ users: ['status', 'id', 'updatedAt'] }],
                displays: {
                  status: {
                    label: 'Status',
                    render: (data: User) => (
                      <StatusField
                        status={formatUserStatus(data?.status)}
                        label={data?.status ?? ''}
                      />
                    ),
                  },
                  updatedAt: {
                    label: 'Last Updated',
                    render: (data: User) =>
                      formatDate(data?.updatedAt, { formatType: 'dateAndTime' }),
                  },
                },
                editControls: ({ resource }) => {
                  const options = Object.values(Status).reduce(
                    (prev, status) => {
                      if (resource?.status === Status.Active) {
                        if ([Status.Active, Status.ProfileLocked, Status.Closed].includes(status)) {
                          prev.push({
                            label: status,
                            value: status,
                          });
                        }
                      }

                      if (resource?.status === Status.ProfileLocked) {
                        if ([Status.ProfileLocked, Status.Active, Status.Closed].includes(status)) {
                          prev.push({
                            label: status,
                            value: status,
                          });
                        }
                      }

                      if (resource?.status === Status.Inactive) {
                        if ([Status.Inactive, Status.Active, Status.Closed].includes(status)) {
                          prev.push({
                            label: status,
                            value: status,
                          });
                        }
                      }

                      if (resource?.status === Status.Closed) {
                        if ([Status.Closed].includes(status)) {
                          prev.push({
                            label: status,
                            value: status,
                          });
                        }
                      }

                      return prev;
                    },
                    [] as { label: string; value: string }[]
                  );

                  return {
                    status: {
                      type: 'select',
                      config: {
                        label: 'Status',
                        disabled: resource?.status === Status.Closed,
                        options,
                      },
                    },
                    updatedAt: {
                      type: 'hidden',
                      required: false,
                      config: {
                        label: 'Last Updated',
                      },
                    },
                  };
                },
              }),
            ],
          },
          {
            label: 'Transaction History',
            content: [
              defineRelatedResourceSection<Transaction>({
                relatedResourceName: resourceNames.transaction,
                fields: ['masterAccountNo'],
                relatedResourceFields: [
                  'id',
                  'referenceNumber',
                  'transactionDatetime',
                  'transactionType',
                  'masterAccountNo',
                  { fund: ['fundName'] },
                  'amount',
                  'transactionStatus',
                ],
                columns: ({ LinkToDetails }) => [
                  {
                    id: 'referenceNumber',
                    header: 'Transaction Reference Number',
                    accessorKey: 'referenceNumber',
                    cell: (data) => {
                      const refNumber = data.cell.getValue<string>();
                      return (
                        <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
                          {refNumber}
                        </LinkToDetails>
                      );
                    },
                  },
                  {
                    id: 'transactionDatetime',
                    header: 'Transaction Datetime',
                    accessorKey: 'transactionDatetime',
                    cell: (data) => {
                      const datetime = data.cell.getValue<Date>();
                      return formatDate(datetime, { formatType: 'dateAndTime' });
                    },
                  },
                  {
                    id: 'transactionType',
                    header: 'Action',
                    accessorKey: 'transactionType',
                  },
                  {
                    id: 'masterAccountNo',
                    header: 'Master Account Number',
                    accessorKey: 'masterAccountNo',
                  },
                  {
                    id: 'fundName',
                    header: 'Fund Name',
                    accessorKey: 'fund.fundName',
                  },
                  {
                    id: 'amount',
                    header: 'Amount',
                    accessorKey: 'amount',
                    cell: (data) => {
                      const amount = data.cell.getValue<number>();
                      return (
                        <>
                          {amount?.toLocaleString('en-US', { style: 'currency', currency: 'MYR' })}
                        </>
                      );
                    },
                  },
                  {
                    id: 'transactionStatus',
                    header: 'Transaction Status',
                    accessorKey: 'transactionStatus',
                    cell: (data) => {
                      const status = data.cell.getValue<TransactionStatus>();
                      return (
                        <StatusField status={formatTransactionStatus(status)} label={status} />
                      );
                    },
                  },
                ],
                filterControls: {
                  transactionType: {
                    type: 'select',
                    config: {
                      label: 'Action',
                      placeholder: 'Select action',
                      options: Object.values(TransactionType).map((type) => ({
                        label: type,
                        value: type,
                      })),
                    },
                    operator: 'eq',
                  },
                },
                getFilterConfig: ({ id }) => ({
                  collapsible: 'expand-by-default',
                  permanent: [
                    {
                      field: 'accountId' as any,
                      operator: 'eq',
                      value: id,
                    },
                  ],
                }),
              }),
            ],
          },
        ],
      }),
    ],
  }),
});
