import React from 'react';

import { useGraphQLClient } from '@amfintech/refine-react-admin';
import { capitalCase } from 'change-case';
import { gql } from 'graphql-request';

export type Role = {
  name: string;
  access: string;
  maker: string;
  checker: string;
};

export const useAdminPermissionMapping = (): {
  isLoading: boolean;
  result: Record<string, Role>;
} => {
  const client = useGraphQLClient();
  const [permissions, setPermissions] = React.useState<
    { resource: string; action: string; code: string; name: string }[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const query = gql`
    query {
      adminPermissions(perPage: 100) {
        items {
          code
          resource
          action
          name
        }
      }
    }
  `;

  if (isLoading) {
    client.request(query).then((data: any) => {
      setPermissions(data?.adminPermissions?.items);
      setIsLoading(false);
    });
  }

  const result: Record<string, Role> = {};

  if (!isLoading && permissions.length) {
    for (const permission of permissions) {
      result[permission.resource] = {
        ...result[permission.resource],
        name: capitalCase(permission.resource),
        [permission.action]: permission.code,
        ...(permission.resource === 'super-admin' && {
          access: permission.code,
          maker: permission.code,
          checker: permission.code,
        }),
      };
    }
  }

  return { isLoading, result };
};
