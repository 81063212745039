import { HiOutlineEye } from 'react-icons/hi';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  formatDate,
  StatusField,
  Switch,
} from '@amfintech/react-admin-ui';
import { createHelpers, createResource } from '@amfintech/refine-react-admin';

import {
  AccountType,
  ApprovalStatus,
  ModificationEntityType,
  TransactionStatus,
  type Transaction,
} from '~/api';
import { useApproveTransactionMutation } from '~/hooks/use-approve-transaction';
import { useRejectTransactionMutation } from '~/hooks/use-reject-transaction';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<Transaction>({
  resourceName: resourceNames.transaction,
});

export const formatTransactionStatus = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.Void:
      return 'warn';
    case TransactionStatus.Approved:
      return 'success';
    case TransactionStatus.Submitted:
      return 'info';
    case TransactionStatus.Rejected:
      return 'danger';
    default:
      return 'pending';
  }
};

export const transactionResource = createResource({
  name: resourceNames.transaction,
  label: 'Transactions',
  fields: defineFields([
    'id',
    'referenceNumber',
    'transactionDatetime',
    'transactionType',
    'customerName',
    'idType',
    'idNumber',
    { account: ['type'] },
    'masterAccountNo',
    { fund: ['fundName'] },
    { switchToFund: ['fundName'] },
    'amount',
    'transactionStatus',
    'paymentMethod',
    'paymentStatus',
    'approvalStatus',
    'rejectionReason',
    'lastUpdatedBy',
    'createdAt',
    'updatedAt',
  ]),
  defaultValues: {},
  list: {
    filters: {
      collapsible: 'expand-by-default',
    },
    makerChecker: {
      entityType: ModificationEntityType.Transaction,
    },
  },
  filterControls: {
    '.account.is.type': {
      type: 'toggle-switch',
      config: {
        label: 'Account Type',
        required: true,
        toggleItems: Object.entries(AccountType).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
    },
    referenceNumber: {
      type: 'text',
      config: {
        label: 'Transaction Reference Number',
      },
      operator: 'contains',
    },
    transactionStatus: {
      type: 'select',
      config: {
        label: 'Transaction Status',
        options: Object.entries(TransactionStatus).map(([key, value]) => ({
          label: key,
          value: value,
        })),
      },
      operator: 'eq',
    },
    idNumber: {
      type: 'text',
      config: {
        label: 'ID Number',
      },
      operator: 'eq',
    },
  },
  allowCreate: false,
  allowDownload: true,
  defaultSorter: [{ field: 'transactionDatetime', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'referenceNumber',
      header: 'Transaction Reference Number',
      accessorKey: 'referenceNumber',
      cell: (data) => {
        const refNumber = data.cell.getValue<string>();
        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {refNumber}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'transactionDatetime',
      header: 'Transaction DateTime',
      accessorKey: 'transactionDatetime',
      cell: (data) => {
        const datetime = data.cell.getValue<Date>();
        return <>{formatDate(datetime, { formatType: 'dateAndTime' })}</>;
      },
    },
    {
      id: 'transactionType',
      header: 'Action',
      accessorKey: 'transactionType',
    },
    {
      id: 'customerName',
      header: 'Client/ Customer Name',
      accessorKey: 'customerName',
    },
    {
      id: 'idNumber',
      header: 'ID Number',
      accessorKey: 'idNumber',
    },
    {
      id: 'masterAccountNo',
      header: 'Master Account Number',
      accessorKey: 'masterAccountNo',
    },
    {
      id: 'fundName',
      header: 'Fund Name',
      accessorKey: 'fundName',
    },
    {
      id: 'amount',
      header: 'Amount/Units',
      accessorKey: 'amount',
      cell: (data) => {
        const amount = data.cell.getValue<number>();
        return Number(amount)?.toLocaleString('en-US', {
          style: 'decimal',
          currency: 'MYR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
    },
    {
      id: 'transactionStatus',
      header: 'Transaction Status',
      accessorKey: 'transactionStatus',
      cell: (data) => {
        const status = data.cell.getValue<TransactionStatus>();
        return <StatusField status={formatTransactionStatus(status)} label={status} />;
      },
    },
  ],
  allowEdit: false,
  allowDelete: false,
  show: defineShowPage({
    actions: (_, option) => {
      const { mutate: approveMutation } = useApproveTransactionMutation();
      const { mutate: rejectMutation } = useRejectTransactionMutation();

      return (
        <>
          {option?.data?.approvalStatus === ApprovalStatus.Pending && !option?.modification && (
            <div className="flex gap-2">
              <Button onClick={() => approveMutation(option?.id)}>Approve</Button>
              <Button variant="danger" onClick={() => rejectMutation(option?.id)}>
                Reject
              </Button>
            </div>
          )}
        </>
      );
    },
    sections: [
      defineCardSection({
        title: 'Transaction Details',
        collapsible: 'expand-by-default',
        fields: [
          'referenceNumber',
          'transactionDatetime',
          'transactionType',
          'customerName',
          'idType',
          'idNumber',
          { account: ['highNetWorth'] },
          'masterAccountNo',
          { fund: ['fundName'] },
          { switchToFund: ['fundName'] },
          'amount',
          'transactionStatus',
          'salesCharge',
          'paymentMethod',
          'paymentStatus',
          'approvalStatus',
          'rejectionReason',
          'signature',
          'lastUpdatedBy',
          'updatedAt',
          'createdAt',
        ],
        displays: {
          referenceNumber: {
            label: 'Transaction Reference Number',
          },
          transactionDatetime: {
            label: 'Transaction Date',
            type: 'date',
            formatType: 'dateAndTime',
          },
          transactionType: {
            label: 'Transaction Type',
          },
          customerName: {
            label: 'Customer Name',
          },
          idType: {
            label: 'ID Type',
          },
          idNumber: {
            label: 'ID Number',
          },
          masterAccountNo: {
            label: 'Master Account Number',
          },
          'fund.fundName': {
            label: 'Fund Name',
          },
          'switchToFund.fundName': {
            label: 'Switch To Fund Name',
          },
          amount: {
            label: 'Amount/ Units',
            render: (data: Transaction) =>
              data.amount?.toLocaleString('en-US', { style: 'currency', currency: 'MYR' }),
          },
          unitSwitch: {
            label: 'Unit Switch',
          },
          salesCharge: {
            label: 'Sales Charge (%)',
            render: (data: Transaction) => `${data.salesCharge}%`,
          },
          transactionStatus: {
            label: 'Transaction Status',
          },
          paymentMethod: {
            label: 'Payment Method',
          },
          paymentStatus: {
            label: 'Payment Status',
          },
          bankTransferDetails: {
            label: 'Bank Transfer Details',
          },
          redemptionPaymentStatus: {
            label: 'Redemption Payment Status',
          },
          investorDeclaration: {
            label: 'Investor Declaration',
            render: (data: Transaction) => (data.investorDeclaration ? 'Yes' : 'No'),
          },
          remarks: {
            label: 'Remarks',
          },
          attachment: {
            label: 'Attachment',
          },
          approvalStatus: {
            label: 'Approval Status',
          },
          rejectionReason: {
            label: 'Rejection Reason',
          },
          lastUpdatedBy: {
            label: 'Last Updated By',
          },
          createdAt: {
            label: 'Created At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          updatedAt: {
            label: 'Last Updated At',
            type: 'date',
            formatType: 'dateAndTime',
          },
          'account.highNetWorth': {
            label: 'High Net Worth',
            render: (data: Transaction) => {
              return <Switch checked={data.account.highNetWorth} disabled />;
            },
          },
          signature: {
            label: 'Signature',
            render: (data: Transaction) => {
              if (!data.signature) return 'No signature';
              return (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant={'secondary'} size="sm" className="gap-2">
                      <HiOutlineEye className="h-4 w-4" />
                      View
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <div className="flex justify-center items-center h-full">
                      <img src={data.signature} alt="Signature" className="max-w-full max-h-full" />
                    </div>
                  </DialogContent>
                </Dialog>
              );
            },
          },
        },
      }),
    ],
  }),
});
